import { useCallback, useContext, useEffect, useState } from "react";
import { callComponants } from "../../services/youtube-api-call-components";
import {
  REDUCER_PROPS,
  SearchContext,
} from "../../state-management/search-provider";

export default function useVideos() {
  const { searchDispatch } = useContext(SearchContext);
  const { LOADING } = REDUCER_PROPS;
  const [videos, setVideos] = useState([]);
  const [nextPageToken, setNextPageToken] = useState();
  const [totalVideosCount, setTotalVideosCount] = useState();
  //   const [prevPageToken, setPrevPageToken] = useState();
  const [pageToken, setPageToken] = useState();

  async function setURL(token) {
    const apiKey = callComponants.key;
    const baseURL = callComponants.baseURL;
    const parts = callComponants.parts;
    const maxResults = callComponants.maxResults;
    const playlistID = callComponants.playlistID;
    const fields = callComponants.fields.videoList;

    const urlPrefix = `${baseURL}?part=${parts}&maxResults=${maxResults}`;
    const urlSuffix = `&fields=${fields}&key=${apiKey}`;

    if (token === undefined || token === null) {
      return `${urlPrefix}&playlistId=${playlistID.allUplaods}${urlSuffix}`;
    }
    return `${urlPrefix}&pageToken=${token}&playlistId=${playlistID.allUplaods}${urlSuffix}`;
  }

  async function setData(data) {
    setTotalVideosCount(data.pageInfo.totalResults);
    setNextPageToken(data.nextPageToken);
    setVideos((currentVideos) => currentVideos.concat(data.items));
  }

  const fetchVideos = useCallback(async (token) => {
    searchDispatch({ type: LOADING, loading: true });
    // await wait(1500);

    const url = await setURL(token);
    const headers = {
      headers: {
        Accept: "application/json",
      },
    };

    const response = await fetch(url, headers);

    if (response.status === 200) {
      const data = await response.json();
      await setData(data);
      searchDispatch({ type: LOADING, loading: false });
    }
  }, []);

  useEffect(() => {
    fetchVideos();
  }, []);

  async function addVideos(e) {
    e.preventDefault();
    e.stopPropagation();
    await fetchVideos(nextPageToken);
    console.log(
      `total videos loaded: ${videos.length}, total videos: ${totalVideosCount}`
    );
  }

  return [videos, totalVideosCount, addVideos];
}

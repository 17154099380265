import { Box, Typography } from "@mui/material";

export default function Support() {
  return (
    <Box display="flex" justifyContent="center" width="100vw">
      <Box maxWidth={720} sx={{ m: "80px 20px 80px 20px" }}>
        <Typography variant="h3" sx={{ marginY: "40px" }}>
          App Support
        </Typography>
        <Typography sx={{ marginY: "20px" }}>
          For iOS app support, contact us at{" "}
          <a href="mailto:thewaymediaco@gmail.com" target="_blank">
            thewaymediaco@gmail.com
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

import { createContext, useReducer } from "react";

export const REDUCER_PROPS = {
  SEARCH: "SEARCH",
  LOADING: "LOADING",
};

const INITIAL_STATE = {
  searchText: "",
  loading: false,
};

export const SearchContext = createContext(INITIAL_STATE);

export const SearchContextProvider = ({ children }) => {
  const [state, searchDispatch] = useReducer(ContextReducer, INITIAL_STATE);

  return (
    <SearchContext.Provider
      value={{
        searchText: state.searchText,
        loading: state.loading,
        searchDispatch,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

const ContextReducer = (state, action) => {
  const { SEARCH, LOADING } = REDUCER_PROPS;
  switch (action.type) {
    case SEARCH: {
      return { ...state, searchText: action.searchText };
    }
    case LOADING: {
      return { ...state, loading: action.loading };
    }
    default:
      return state;
  }
};

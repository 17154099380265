/* eslint-disable */
export const callComponants = {
  key: "AIzaSyB_BTTozalqgF27IQfCfSVubrA0ApPLqq0",
  baseURL: "https://youtube.googleapis.com/youtube/v3/playlistItems",
  parts: "snippet%2CcontentDetails",
  maxResults: "48",
  channelID: "UCbvdTix5zc4XaEVgEVEfXrw",
  playlistID: {
    // worshipServices: "PLkSQb7eVv34pW71TtjNcscLEd_IooEMAZ",
    // studentWorship: "PLkSQb7eVv34owZQ-lFVS4TurTwRhkibOD",
    // other: "PLkSQb7eVv34rAfekODd3PJRUT-MiJXC0U",
    allUplaods: "UUbvdTix5zc4XaEVgEVEfXrw",
  },
  fields: {
    videoList:
      "etag%2CnextPageToken%2CprevPageToken%2Citems(snippet(title%2Cdescription%2Cthumbnails(high(url))%2Cposition)%2CcontentDetails)%2CpageInfo",
    livePlaylist: "etag%2Citems(snippet(resourceId(videoId)))",
    liveVideo:
      "etag%2Citems(etag%2Cid%2Csnippet(title%2CliveBroadcastContent))",
  },
};

/* eslint-disable */
import React, { useContext, useRef, useState } from "react";

// Material Kit 2 PRO React components
import "../styles/video-section.css";
import YouTubePlayer from "../../components/YouTubePlayer/YouTubePlayer";
import VideoGrid from "../components/VideoGrid";
// import FilterTabs from "components/FilterTabs";
import { Box, Collapse } from "@mui/material";
import useVideos from "./video-hook";
import { SearchContext } from "../../state-management/search-provider";

// const VideoFilters = {
//   worshipServices: "Wosrhip Services",
//   studentWorship: "Student Worship",
//   other: "Other Videos",
// };

function VideosSection() {
  const { searchText } = useContext(SearchContext);
  const ref = useRef(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, addVideos, totalVideosCount] = useVideos();
  const searchParam = ["title", "description"];
  // const tabOptions = [
  //   VideoFilters.worshipServices,
  //   VideoFilters.studentWorship,
  //   VideoFilters.other,
  // ];
  // const [activeTab, setActiveTab] = useState(tabOptions[0]);

  function selectVideo(e, video) {
    e.preventDefault();
    e.stopPropagation();

    setSelectedVideo(video);
    // ref.current?.scrollIntoView({ behavior: "smooth" });
  }

  const search = (videos) => {
    return videos?.filter((video) => {
      return searchParam.some((newVideo) => {
        return (
          video.snippet[newVideo]
            .toString()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        );
      });
    });
  };

  return (
    <Box sx={{ py: "120px" }}>
      {/* <FilterTabs
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setPageToken={setPageToken}
        setPage={setPage}
      /> */}
      {selectedVideo !== null && (
        <Box ref={ref} mx="auto" mb="40px" maxWidth="1440px">
          <Collapse in={selectedVideo !== null} timeout="auto" unmountOnExit>
            <YouTubePlayer video={selectedVideo} />
          </Collapse>
        </Box>
      )}
      <VideoGrid selectedVideo={selectedVideo} selectVideo={selectVideo} />
    </Box>
  );
}

export default VideosSection;

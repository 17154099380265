/* eslint-disable */
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import VideoButton from "../components/VideoButton";
import React, { useContext } from "react";
import { wait } from "../../utils/utils";
import useVideos from "../sections/video-hook";
import {
  REDUCER_PROPS,
  SearchContext,
} from "../../state-management/search-provider";

function VideoGrid({ selectedVideo, selectVideo }) {
  const { searchText, loading, searchDispatch } = useContext(SearchContext);
  const { LOADING } = REDUCER_PROPS;
  // const [{ searchText, loading }, dispatch] = useStateValue();
  const searchParam = ["title", "description"];
  const [videos, totalVideosCount, addVideos] = useVideos();

  const search = (videos) => {
    return videos?.filter((video) => {
      return searchParam.some((newVideo) => {
        return (
          video.snippet[newVideo]
            .toString()
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        );
      });
    });
  };

  const handleAddVideos = async (e) => {
    searchDispatch({ type: LOADING, loading: true });
    await wait(1500);
    await addVideos(e);
    searchDispatch({ type: LOADING, loading: false });
  };

  return (
    <Box component="section" py={2}>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 3 }}
          columns={{ xs: 1, sm: 3, md: 4, lg: 6 }}
          my={6}
          mx="auto"
        >
          {search(videos)?.map((video) => (
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              role="button"
              tabIndex="0"
              key={video?.contentDetails?.videoId}
              onClick={(e) => selectVideo(e, video)}
              onKeyDown={(e) => selectVideo(e, video)}
            >
              <VideoButton video={video} selectedVideo={selectedVideo} />
            </Grid>
          ))}
          <Grid item container justifyContent="center">
            {videos.length !== 0 && videos.length !== totalVideosCount && (
              <Grid item container justifyContent="center">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={handleAddVideos}
                >
                  {loading && (
                    <CircularProgress
                      size={24}
                      color="primary"
                      sx={{
                        // color: green[500],
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  {loading ? "Loading" : "Load More"}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default VideoGrid;

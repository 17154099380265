import React, { useContext } from "react";
import { Box, Tooltip } from "@mui/material";
import { truncateString } from "../../utils/utils";
import VideoCardSkeleton from "../../components/VideoCardSkeleton";
import { SearchContext } from "../../state-management/search-provider";

function VideoButton({ video, selectedVideo }) {
  const { loading } = useContext(SearchContext);

  const selectedVideoCheck = () => {
    if (selectedVideo !== null)
      return (
        selectedVideo.contentDetails?.videoId === video.contentDetails?.videoId
      );

    return false;
  };

  return (
    <Box
      width="100%"
      style={{
        margin: selectedVideoCheck() ? "-3px" : null,
        padding: "6px",
        border: selectedVideoCheck() ? "3px solid rgba(207, 89, 89, 1)" : null,
        borderRadius: "6px",
      }}
    >
      <VideoCardSkeleton loading={loading}>
        <img
          className="video-thumb"
          src={video.snippet?.thumbnails?.high?.url}
          alt={video.snippet?.title}
          style={{
            aspectRatio: "16 / 9",
            objectFit: "cover",
            filter: selectedVideoCheck() ? "brightness(25%)" : null,
            boxShadow: "2px 2px 8px rgba(0,0,0,0.25)",
          }}
        />
      </VideoCardSkeleton>
      {!loading && (
        <Tooltip title={video.snippet?.title}>
          <h5
            style={{
              // maxWidth: "160px",
              marginLeft: "6px",
              marginTop: "4px",
              color: selectedVideoCheck() ? "rgba(207, 89, 89, 1)" : null,
            }}
          >
            {truncateString(video.snippet?.title, 60)}
          </h5>
        </Tooltip>
      )}
    </Box>
  );
}

// VideoButton.propTypes = {
//   video: PropTypes.instanceOf(Object).isRequired,
//   selectedVideo: PropTypes.instanceOf(Object).isRequired,
// };

export default VideoButton;

import { Skeleton } from "@mui/material";

export default function CustomSkeleton(props) {
  const { loading, children } = props;

  if (loading) {
    return (
      <>
        <Skeleton
          variant="rounded"
          sx={{
            borderRadius: "6px",
            boxShadow: "2px 2px 8px rgba(0,0,0,0.25)",
          }}
        >
          {children}
        </Skeleton>
        <Skeleton height="100%" sx={{ mt: "10px" }} />
        <Skeleton height="100%" width="60%" />
      </>
    );
  } else {
    return children;
  }
}

import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import logo from "../assets/crd-icon-white.png";

export default function LogoAvatar() {
  return (
    <Stack direction="row" spacing={2}>
      {/* <img src={logo} /> */}
      <Avatar alt="Remy Sharp" src={logo} sx={{ width: 44, height: 44 }} />
    </Stack>
  );
}

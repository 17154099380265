import { Box, Typography } from "@mui/material";

export default function Privacy() {
  return (
    <Box display="flex" justifyContent="center" width="100vw">
      <Box maxWidth={720} sx={{ m: "80px 20px 80px 20px" }}>
        <Typography variant="h3" sx={{ marginY: "40px" }}>
          Privacy Policy
        </Typography>
        <Typography variant="h5" sx={{ marginY: "20px", fontWeight: "bold" }}>
          Detailed information on the processing of Personal Data
        </Typography>
        <Typography sx={{ marginY: "20px" }}>
          Personal Data is collected for the following purposes and using the
          following services:
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginY: "20px", fontWeight: "bold" }}
        >
          Advertising
        </Typography>
        <Typography sx={{ marginY: "20px" }}>
          This type of services allows User Data to be utilized for advertising
          communication purposes displayed in the form of banners and other
          advertisements on this Application, possibly based on User interests.
          This does not mean that all Personal Data are used for this purpose.
          Information and conditions of use are shown below. Some of the
          services listed below may use Cookies to identify Users or they may
          use the behavioral retargeting technique, i.e. displaying ads tailored
          to the User’s interests and behavior, including those detected outside
          this Application. For more information, please check the privacy
          policies of the relevant services.
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ marginY: "20px", fontWeight: "bold" }}
        >
          AdMob (AdMob Google Inc.)
        </Typography>
        <Typography sx={{ marginY: "20px" }}>
          AdMob is an advertising service provided by AdMob Google Inc. Personal
          Data collected: Cookies and Usage data. Place of processing: US –{" "}
          <a
            href="https://www.google.com/policies/technologies/ads/"
            target="_blank"
          >
            Privacy Policy
          </a>
        </Typography>
      </Box>
    </Box>
  );
}

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import PrimarySearchAppBar from "./components/AppBar";
import Helmet from "react-helmet";
import VideosSection from "./Video/sections/VideosSection";
import Privacy from "./Privacy";
import Support from "./Support";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "rgba(207, 89, 89, 1)",
    },
    secondary: {
      main: "rgba(140, 147, 155, 1)",
    },
    success: {
      main: "#66bb6a",
    },
    error: {
      main: "#f44336",
    },
    info: {
      main: "#708090",
    },
    background: {
      default: "#f7f4f3",
    },
  },
});

function App() {
  return (
    <>
      <Helmet>
        <title>Calf Roping Daily</title>
        <meta
          name="description"
          content="We bring daily highlights of professional calf roping (Tie-down Roping) from major PRCA rodeos on the road to the NFR. We're following the ProRodeo season as it unfolds and tracking along as the best calf ropers in the world try to earn their spot into the National Finals Rodeo."
        ></meta>
        <meta
          name="keywords"
          content="Rodeo,CALF ROPING,tie-down roping,cowboy,ranch,horses,shad mayfield,tuf cooper,rodeo highlights,cory solomon,ryan jarrett,Haven Meged,Ty Harris,pro rodeo,Marty Yates,Shane Hanchey,marcos costa,cody ohl,joe beaver,fred whitfield,nfr,national finals rodeo,professional rodeo cowboys association,prca,San Antonio,Cheyenne,calgary,dale,brisby,houston,fort worth,calf roping highlights,calf roping 2022,calf roping tips,the american rodeo"
        ></meta>
      </Helmet>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <PrimarySearchAppBar />
          <Routes>
            <Route path="/support" element={<Support />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/" element={<VideosSection />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
